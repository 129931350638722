import { Box } from "@cloudline/cloudline-styled-components";
import { CarouselSection } from "components/CarouselSection";
import Skeleton from "react-loading-skeleton";
import { useNotices } from "../hooks/useNotices";
import { NoticeCard } from "./NoticeCard";

export const NoticeCarousel = () => {
  const { data: { ids = [] } = {}, isInitialLoading } = useNotices();

  if (isInitialLoading)
    return (
      <Box mt={2}>
        <Skeleton height={360} width="100%" style={{ borderRadius: "10px" }} />
      </Box>
    );

  return (
    <CarouselSection
      IDs={ids}
      SubComponent={NoticeCard}
      useDots
      isAutoPlay
      loop
      sliceLength={Infinity} // To stop the View More Button
    />
  );
};
