import { S3Object } from "types";

export type Notice = {
  _deleted?: boolean | null;
  _version: number;
  activityID?: string;
  coverImage: S3Object;
  description?: string;
  embedURL?: string | null;
  id: string;
  isLive: boolean;
  link?: string | null;
  order: number;
  title: string;
  type: NoticeType;
};

export type ListNoticesQuery = {
  getNoticesBySite?: {
    nextToken?: string | null;
    items: Array<{
      id: string;
      title: string;
      order: number;
      embedURL?: string | null;
      type: NoticeType;
      isLive: boolean;
      link?: string | null;
      _version: number;
      _deleted?: boolean | null;
      coverImage: S3Object;
    }>;
  };
};

export enum NoticeType {
  Activity = "ACTIVITY",
  Info = "INFO",
  Link = "LINK",
  Embed = "EMBED"
}
