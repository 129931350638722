import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { isIOS, isMobileSafari } from "react-device-detect";

function shouldPrompt() {
  const isIOSSafari = isIOS && isMobileSafari;
  const isPWA = window.matchMedia("(display-mode: standalone)").matches;

  // If the user is not on iOS Safari or they are using the
  // PWA installed on their phone, don't display the prompt.
  if (isPWA || !isIOSSafari) {
    return false;
  }

  const today = dayjs().toISOString();
  const lastDismissed = dayjs(localStorage.getItem("installPrompt"));
  const days = dayjs(today).diff(lastDismissed, "days");

  // Show the prompt once every 7 days.
  const prompt = isNaN(days) || days > 7;

  return prompt;
}

/**
 * Checks if the PWA prompt should be displayed or not. If it's the Visitor's
 * first time or it's been a certain amount of time since they last dismissed
 * the prompt, display it.
 */
export const useShowPWAPrompt = () => {
  const [showPrompt, setShowPrompt] = useState(false);
  const promptValue = shouldPrompt();

  useEffect(() => {
    setShowPrompt(promptValue);
  }, [promptValue]);

  return showPrompt;
};
