import { Flex, Show, Text } from "@cloudline/cloudline-styled-components";
import Link from "next/link";
import { memo } from "react";
import { lato } from "styles/globals";
import { CarouselComponent, EmblaCarousel } from "./EmblaCarousel";

interface Props {
  IDs: string[];
  subtitle?: string;
  title?: string;
  link?: string;
  sliceLength?: number;
  SubComponent: CarouselComponent;
  useDots?: boolean;
  isAutoPlay?: boolean;
  loop?: boolean;
  startIndex?: number;
  viewMoreText?: string;
}

export const CarouselSection = memo(function CarouselSection({
  subtitle,
  title,
  link,
  sliceLength,
  SubComponent,
  useDots,
  isAutoPlay,
  loop,
  IDs,
  viewMoreText
}: Props) {
  if (!IDs.length) return null;

  return (
    <Flex column gap={3}>
      <div>
        <Show when={subtitle}>
          <Text
            size={12}
            weight={400}
            color="grey.600"
            transform="uppercase"
            font={lato}
          >
            {subtitle}
          </Text>
        </Show>

        <Show when={title}>
          <Text size={28} weight={700} letterSpacing={-1}>
            {title}
          </Text>
        </Show>
      </div>

      <EmblaCarousel
        IDs={IDs}
        SubComponent={SubComponent}
        useDots={useDots}
        isAutoplay={isAutoPlay}
        link={link}
        sliceLength={sliceLength}
        loop={loop}
        viewMoreText={viewMoreText}
      />

      <Show when={IDs.length > (sliceLength || 3) && link}>
        <Link href={link || ""}>
          <Text color="primary.500" weight={500}>
            {viewMoreText || "View All"}
          </Text>
        </Link>
      </Show>
    </Flex>
  );
});
