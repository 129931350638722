import { Box, Text, theme } from "@cloudline/cloudline-styled-components";
import dayjs from "dayjs";
import { useDisclosure } from "hooks/useDisclosure";
import { useShowPWAPrompt } from "hooks/useShowPWAPrompt";
import { memo, useEffect } from "react";
import { MdIosShare, MdOutlineAddBox } from "react-icons/md";
import { GenericBottomSheet } from "./GenericBottomSheet";
import { Icon } from "./Icon";

export const PWAPrompt = memo(function PWAPrompt() {
  const showPrompt = useShowPWAPrompt();

  const { isShowing, open, close } = useDisclosure();

  const dismissPrompt = () => {
    const today = dayjs().toISOString();
    localStorage.setItem("installPrompt", today);
    close();
  };

  useEffect(() => {
    setTimeout(() => {
      if (showPrompt) open();
    }, 3000);
  }, [open, showPrompt]);

  return (
    <GenericBottomSheet
      Content={
        <Box
          bg={theme.color.grey[100]}
          overflow="hidden"
          px={4}
          py={6}
          flex
          column
          gap={4}
        >
          <Text size={18} weight={500}>
            Add to Home Screen
          </Text>

          <Text size={14}>
            Install this app to your phone for push notifications by following
            the instructions below:
          </Text>

          <Box flex column gap={2}>
            <Box flex align="center" gap={3}>
              <Icon icon={<MdIosShare size={24} color="rgb(45, 124, 246)" />} />
              <Text size={14}>
                {"1. Press the 'Share' button on the menu bar below"}
              </Text>
            </Box>
            <Box flex align="center" gap={3}>
              <Icon
                icon={<MdOutlineAddBox size={24} color="rgb(45, 124, 246)" />}
              />
              <Text size={14}>{"2. Press 'Add to Home Screen'"}</Text>
            </Box>
          </Box>
        </Box>
      }
      onTap={close}
      onClose={close}
      onCloseEnd={dismissPrompt}
      isOpen={isShowing}
    />
  );
});
