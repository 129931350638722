import { normalise } from "utils/normalise";
import { ActivityType } from "../types";
import { useActivities } from "./useActivities";

/** Select all activities of a certain ActivityType */
export const useActivityType = (type: ActivityType) => {
  const { data: { entities = {} } = {} } = useActivities();

  const selectedActivities = Object.values(entities).filter(
    activity => activity.type === type
  );

  return normalise(selectedActivities);
};
