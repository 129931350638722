import { Text, theme } from "@cloudline/cloudline-styled-components";
import { Activity, ActivityType } from "features/activities/types";
import styled from "styled-components";
import { Status } from "types";

const Wrapper = styled.div`
  border-radius: 25px;
  background: #fff;
  width: max-content;
  max-width: 65%;
  padding: 4px 12px;
  margin-left: 12px;
`;

export enum Variant {
  "success",
  "danger",
  "warning"
}

// Generate if we are to display custom message or not function
function renderStatusMessage(activity: Activity) {
  switch (activity.status) {
    case Status.Open:
      return activity.customStatusMessages?.customOpenMessage || "Open";

    case Status.Closed:
      return activity.customStatusMessages?.customClosedMessage || "Closed";

    case Status.Unavailable:
      if (activity?.customStatusMessages?.customUnavailableMessage) {
        return activity.customStatusMessages.customUnavailableMessage;
      }

      return activity.type === ActivityType.Event
        ? "No Times Today"
        : "Currently unavailable";

    default:
      return null;
  }
}

interface Props {
  activity: Activity;
}

const StatusColors = {
  [Status.Open]: theme.color.success[500],
  [Status.Closed]: theme.color.error[300],
  [Status.Unavailable]: theme.color.warning[300]
};

export const ActivityStatusTag = ({ activity }: Props) => (
  <Wrapper>
    <Text
      size={12}
      weight={600}
      lines={1}
      color={StatusColors[activity.status]}
    >
      {renderStatusMessage(activity)}
    </Text>
  </Wrapper>
);
