import { useQuery } from "@tanstack/react-query";
import { useSite } from "features/site/hooks/useSite";
import { getAuthMode } from "utils/getAuthMode";
import { Data, normalise } from "utils/normalise";
import { requestWithErrHandler } from "utils/requestWithErrHandler";
import { ListNoticesQuery, Notice } from "../types";

export const fetchNotices = async (siteID: string) => {
  const authMode = await getAuthMode();

  const response = await requestWithErrHandler<
    ListNoticesQuery,
    { siteID: string }
  >(listNotices, { siteID }, authMode);

  const notices = response.data?.getNoticesBySite?.items;

  return normalise<Notice>(
    notices?.filter(({ isLive, _deleted }) => isLive && !_deleted) || [],
    "order"
  );
};

export const useNotices = () => {
  const { data: { id } = {} } = useSite();

  return useQuery<Data<Notice>, unknown>(
    ["notices", id],
    () => fetchNotices(id || ""),
    { enabled: !!id, retry: false }
  );
};

export const listNotices = /* GraphQL */ `
  query GetNoticesBySite($siteID: ID!) {
    getNoticesBySite(siteID: $siteID, filter: { state: { ne: ARCHIVED } }) {
      items {
        id
        coverImage {
          bucket
          region
          key
        }
        activityID
        isLive
        description
        embedURL
        title
        order
        type
        isLive
        link
        _version
        _deleted
      }
      nextToken
    }
  }
`;
