import { theme } from "@cloudline/cloudline-styled-components";
import { useRouter } from "next/router";
import styled from "styled-components";

const Button = styled.button<{ $background?: string }>`
  background: ${({ $background }) =>
    $background ? $background : theme.color.primary[500]};
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px;
  padding: 0px;
  border-radius: 50%;
`;

interface PageActionButtonProps {
  icon: JSX.Element;
  link?: string;
  background?: string;
  action?: () => void;
  dataPh?: string;
}

/** Action button for the page header. Rendered in the top right corner of a page. */
export const ActionButton = ({
  icon,
  link,
  background,
  action,
  dataPh
}: PageActionButtonProps) => {
  const { push } = useRouter();

  // If link is passed push to link otherwise call action
  function handleClick() {
    if (link) return push(link);

    return action?.();
  }

  return (
    <Button $background={background} onClick={handleClick} data-ph={dataPh}>
      {icon}
    </Button>
  );
};
