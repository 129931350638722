import { Flex, Show } from "@cloudline/cloudline-styled-components";
import { AccountButton } from "components/AccountButton";
import { PWAPrompt } from "components/PWAPrompt";
import { Page } from "components/Page";
import { ActivityCarousel } from "features/activities/components/ActivityCarousel";
import { ActivityType } from "features/activities/types";
import { AppletCarousel } from "features/applets/components/AppletCarousel";
import { NoticeCarousel } from "features/notices/components/NoticeCarousel";
import { useCheckFeatureEnabled } from "features/site/hooks/useCheckFeatureEnabled";
import { memo } from "react";
import { ActivityLinks } from "./ActivityLinks";

export const Today = memo(function Today() {
  const {
    noticesEnabled,
    restaurantActivitiesEnabled,
    sessionActivitiesEnabled,
    eventActivitiesEnabled,
    appletsEnabled
  } = useCheckFeatureEnabled([
    "noticesEnabled",
    "restaurantActivitiesEnabled",
    "sessionActivitiesEnabled",
    "eventActivitiesEnabled",
    "appletsEnabled"
  ]);

  return (
    <Page ActionButtons={<AccountButton />} hasSearchBar>
      <ActivityLinks />

      <Flex column gap={8}>
        <Show when={noticesEnabled}>
          <NoticeCarousel />
        </Show>

        <Show when={restaurantActivitiesEnabled}>
          <ActivityCarousel
            type={ActivityType.Restaurant}
            subtitle="order food and drink around and about"
            title="Food & Drink"
            link="/today/food"
          />
        </Show>

        <Show when={eventActivitiesEnabled}>
          <ActivityCarousel
            type={ActivityType.Event}
            subtitle="add exciting events to your day"
            title="Todays Events"
            link="/today/events"
          />
        </Show>

        <Show when={sessionActivitiesEnabled}>
          <ActivityCarousel
            type={ActivityType.Session}
            subtitle="book your activities for the day"
            title="Bookable Activities"
            link="/today/bookable"
          />
        </Show>

        <Show when={appletsEnabled}>
          <AppletCarousel
            subtitle="Helpful apps for your visit"
            title="Applets"
            link="/"
          />
        </Show>
      </Flex>

      <PWAPrompt />
    </Page>
  );
});
