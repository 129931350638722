import {
  Box,
  Flex,
  Show,
  Text,
  theme
} from "@cloudline/cloudline-styled-components";
import dayjs from "dayjs";
import { useRouter } from "next/router";
import NProgress from "nprogress";
import { useEffect } from "react";
import styled from "styled-components";
import { lato } from "styles/globals";
import { BackButton } from "./BackButton";
import { HomeSearchBar } from "./HomeSearchBar";

NProgress.configure({ showSpinner: false });

export const APP_SPACING = 16;

const Wrapper = styled.div<{ bottomPadding?: boolean }>`
  padding: ${({ bottomPadding }) =>
    bottomPadding
      ? `${APP_SPACING}px ${APP_SPACING}px 138px`
      : `${APP_SPACING}px`};
  overflow: hidden;
`;

interface Props {
  children: React.ReactNode;
  title?: string;
  hasBackButton?: boolean;
  backButtonFunction?: () => void;
  hasDate?: boolean;
  hasSearchBar?: boolean;
  ActionButtons?: React.ReactNode;
  bottomPadding?: boolean;
}

/** Heading for the non search pages. @includes BackButton, Title, Date */
const Heading = ({
  hasBackButton,
  backButtonFunction,
  hasDate,
  title
}: Pick<
  Props,
  "hasBackButton" | "backButtonFunction" | "hasDate" | "title"
>) => {
  const date = dayjs().format("dddd, D MMMM");
  const { back } = useRouter();

  function handleBackButton() {
    if (backButtonFunction) return backButtonFunction();

    return back();
  }

  return (
    <Flex align="center">
      <Show when={hasBackButton}>
        <BackButton handleBackButton={handleBackButton} />
      </Show>
      <Box flex column gap={1}>
        <Show when={hasDate}>
          <Text color={theme.color.grey[600]} font={lato} size={16}>
            {date}
          </Text>
        </Show>
        <Show when={title}>
          <Text
            color={theme.color.grey[900]}
            letterSpacing="-1.5px"
            lineHeight="110%"
            size={32}
            weight={600}
          >
            {title}
          </Text>
        </Show>
      </Box>
    </Flex>
  );
};

/**
 * Page Template.
 * @includes Heading, SearchBar, ActionButtons, Content
 */
export const Page = ({
  title,
  children,
  hasBackButton,
  backButtonFunction,
  hasDate = false,
  hasSearchBar = false,
  ActionButtons,
  bottomPadding = true // For pages that we don't want to have extra padding i.e. Timeline
}: Props) => {
  useEffect(() => {
    NProgress.start();
    NProgress.done();
  }, []);

  return (
    <Wrapper bottomPadding={bottomPadding}>
      <Box
        display="flex"
        align="flex-start"
        justify="space-between"
        gap={4}
        mb={4}
      >
        <Show when={!hasSearchBar}>
          <Heading
            hasBackButton={hasBackButton}
            backButtonFunction={backButtonFunction}
            hasDate={hasDate}
            title={title}
          />
        </Show>
        <Show when={hasSearchBar}>
          <HomeSearchBar />
        </Show>
        <Show when={!!ActionButtons}>{ActionButtons}</Show>
      </Box>
      {children}
    </Wrapper>
  );
};
