import { useCallback, useState } from "react";

/**
 * Custom hook used to help handle common open, close, or toggle scenarios.
 * It can be used to control feedback components such as `Modal` or `Menu`, etc.
 */
export const useDisclosure = () => {
  const [isShowing, setIsShowing] = useState(false);

  const toggle = () => setIsShowing(!isShowing);
  const open = useCallback(() => {
    setIsShowing(true);
  }, []);
  const close = useCallback(() => {
    setIsShowing(false);
  }, []);

  return {
    isShowing,
    toggle,
    open,
    close
  };
};
