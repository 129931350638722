import { useRouter } from "next/router";
import React from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import styled from "styled-components";

const Container = styled.div`
  width: 40px;
  height: 40px;
  display: grid;
  place-content: center;
  margin: 0 1rem 2px 0;
  border-radius: 50%;
  background: #fff;
  z-index: 50;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

interface Props {
  handleBackButton?: () => void;
}

/** Back button for the page layout. */
export const BackButton = ({ handleBackButton }: Props) => {
  const { back } = useRouter();

  return (
    <Container onClick={handleBackButton || back}>
      <IoMdArrowRoundBack size="32px" />
    </Container>
  );
};
