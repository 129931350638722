import { CarouselSection } from "components/CarouselSection";
import Skeleton from "react-loading-skeleton";
import { useActivities } from "../hooks/useActivities";
import { useActivityType } from "../hooks/useActivityType";
import { ActivityType } from "../types";
import { ActivityCard } from "./ActivityCard";

export type Props = {
  title: string;
  subtitle: string;
  link: string;
  type: ActivityType;
};

export const ActivityCarousel = ({ type, ...rest }: Props) => {
  const { isInitialLoading } = useActivities();
  const { ids = [] } = useActivityType(type);

  if (isInitialLoading)
    return (
      <Skeleton height={280} width="100%" style={{ borderRadius: "10px" }} />
    );

  return (
    <CarouselSection
      IDs={ids}
      SubComponent={ActivityCard}
      sliceLength={3}
      {...rest}
    />
  );
};
