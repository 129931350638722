import { useEffect, useState } from "react";
import { toMinutes } from "utils/toMinutes";
import { ActivityType, WaitTimeVisibility } from "../types";
import { useActivity } from "./useActivity";

interface Props {
  /**
   * ActivityID of the restaurant.
   */
  id: string;
  /**
   * Which page of the app it's being used.
   */
  location: WaitTimeVisibility;
}

/**
 * Get all possible wait time intervals starting at the lowest multiple of
 * 5 minutes from the minimum wait time and ending at the highest multiple
 * of 5 minutes from the maximum wait time.
 */
function generateWaitTimeIntervals(minTime: number, maxTime: number) {
  const start = Math.floor(minTime / 5) * 5;
  const end = Math.ceil(maxTime / 5) * 5;

  const intervals: string[] = [];

  for (let i = start; i <= end; i += 5) {
    intervals.push(`${i} - ${i + 5}`);
  }
  return intervals;
}

/**
 * Returns the interval best suited for the given wait time.
 */
function getWaitTimeInterval(waitTime: number, waitTimeIntervals: string[]) {
  const interval = waitTimeIntervals.find(
    (interval: string) =>
      +interval.split(" - ")[0] <= waitTime &&
      +interval.split(" - ")[1] >= waitTime
  );
  return interval || "";
}

/**
 * Returns the wait time of a restaurant and whether wait times are enabled.
 */
export const useWaitTimes = ({ id, location }: Props) => {
  const [waitTimeInterval, setWaitTimeInterval] = useState<string>("");
  const { type, waitTimes } = useActivity(id);

  const waitTimesEnabled = (waitTimes?.enabled &&
    (!!waitTimes?.busyMessage || !!waitTimes?.preparationTimes?.kitchenTime) &&
    waitTimes?.visibility[location]) as boolean;

  // Calculating which 5 minute interval the current calculated wait time falls into
  useEffect(() => {
    if (waitTimesEnabled && !waitTimes?.busyOverride) {
      const minTime = toMinutes(waitTimes?.preparationTimes?.orderMinTime || 0);

      const maxTime = toMinutes(
        waitTimes?.preparationTimes?.kitchenBusyTime || 0
      );

      const waitTimeIntervals = generateWaitTimeIntervals(minTime, maxTime);
      const interval = getWaitTimeInterval(
        toMinutes(waitTimes?.preparationTimes?.kitchenTime || 0),
        waitTimeIntervals
      );

      setWaitTimeInterval(interval);
    }
  }, [waitTimes, waitTimesEnabled]);

  if (type !== ActivityType.Restaurant) return;

  const waitTime = waitTimes?.busyOverride
    ? waitTimes?.busyMessage
    : waitTimeInterval;

  return { waitTimesEnabled, waitTime };
};
