import { CarouselSection } from "components/CarouselSection";
import { useApplets } from "features/applets/hooks/useApplets";
import Skeleton from "react-loading-skeleton";
import { AppletCard } from "./AppletCard";

type Props = {
  title: string;
  subtitle: string;
  link: string;
};

export const AppletCarousel = (props: Props) => {
  const { data: { ids = [] } = {}, isInitialLoading } = useApplets();

  if (isInitialLoading)
    return (
      <Skeleton height={280} width="100%" style={{ borderRadius: "10px" }} />
    );

  return (
    <CarouselSection
      IDs={ids}
      SubComponent={AppletCard}
      sliceLength={3}
      {...props}
    />
  );
};
