import { Box, theme } from "@cloudline/cloudline-styled-components";
import { useCustomPages } from "features/site/hooks/useCustomPages";
import { useSite } from "features/site/hooks/useSite";
import Link from "next/link";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

const Container = styled.div`
  overflow-x: scroll;
  margin: 0.25rem -1.5rem;
  padding: 4px 1.5rem;
  position: relative;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ScrollContainer = styled.div`
  display: flex;
  width: 100%;
  top: 5px;
  left: 1.5rem;
  padding: 1px 4rem 1px 0;
`;

const LinkContainer = styled.div`
  height: fit-content;
  min-width: fit-content;

  &:last-of-type {
    padding-right: 12px;
  }
`;

const ActivityLink = styled(Link)`
  width: 100%;
  min-width: fit-content;
  height: 100%;
  margin-right: 0.75rem;
  padding: 4px 12px;
  border-radius: 12px;
  background: #fff;
  color: ${theme.color.grey[900]};
  font-size: 1rem;
  font-weight: 600;
  border: 1px solid ${theme.color.grey[400]};

  &:hover {
    color: ${theme.color.grey[900]};
  }

  &:visited {
    color: ${theme.color.grey[900]};
  }
`;

// Loading skeleton shown while data is loading
const SkeletonLoader = () => (
  <Box flex gap={3} mt={1} mb={4}>
    <Skeleton width={100} height={32} style={{ borderRadius: "12px" }} />
    <Skeleton width={100} height={32} style={{ borderRadius: "12px" }} />
    <Skeleton width={100} height={32} style={{ borderRadius: "12px" }} />
  </Box>
);

// These links are used in the home page and they link to pages than contain
// activities of a specified category such as Restaurants, Events, Sessions
// and Points of Interest
export const ActivityLinks = () => {
  const { isInitialLoading } = useSite();
  const customPages = useCustomPages();

  if (isInitialLoading) return <SkeletonLoader />;

  if (!customPages?.pages.length) return null;

  return (
    <Container>
      <ScrollContainer>
        {Object.values(customPages.entities).map(({ route, title }, index) => (
          <LinkContainer key={index}>
            <ActivityLink data-ph="activity tab" href={route}>
              {title}
            </ActivityLink>
          </LinkContainer>
        ))}
      </ScrollContainer>
    </Container>
  );
};
