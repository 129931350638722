import { Box, Flex, Text } from "@cloudline/cloudline-styled-components";
import { Image } from "components/Image";
import {
  CardContainer,
  Gradient
} from "features/activities/components/ActivityCard";
import Link from "next/link";
import { memo } from "react";
import { useApplet } from "../hooks/useApplet";

interface Props {
  id: string;
  $fullWidth?: boolean;
}

export const AppletCard = memo(function ActivityCard({
  id,
  $fullWidth
}: Props) {
  const applet = useApplet(id);

  return (
    <Link href={`/applet?applet=${id}`} data-ph="applet card">
      <CardContainer whileTap={{ scale: 0.95 }} $fullWidth={$fullWidth}>
        <Image
          alt={applet.name}
          s3Key={applet.image?.key || ""}
          fill
          priority
        />
        <Gradient />
        <Box
          h="100%"
          w="100%"
          position="absolute"
          bottom={0}
          z={5}
          p={4}
          display="flex"
          direction="column"
          justify="flex-end"
        >
          <Flex direction="column" gap={1}>
            <Text
              size={36}
              weight={600}
              color="grey.100"
              lineHeight="1.2"
              lines={1}
            >
              {applet.name}
            </Text>
            <Text
              size={14}
              weight={300}
              color="grey.100"
              lines={2}
              lineHeight="17px"
            >
              {applet.description}
            </Text>
          </Flex>
        </Box>
      </CardContainer>
    </Link>
  );
});
