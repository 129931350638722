import { Button, Flex, theme } from "@cloudline/cloudline-styled-components";
import { useVisitor } from "features/auth/hooks/useVisitor";
import { useRouter } from "next/router";
import { IoPerson } from "react-icons/io5";
import { ActionButton } from "./ActionButton";

/**
 * Renders an account button that will redirect the user to the account page if they are logged in.
 * If they are not logged in, it will redirect them to the login page.
 */
export const AccountButton = () => {
  const { push } = useRouter();
  const { data: user } = useVisitor();

  function handleClick() {
    return user ? push("/account") : push("/login");
  }

  if (!user)
    return (
      <Flex justify="center" align="center" w="fit-content" h="48px">
        <Button
          value="Login"
          size="sm"
          style={{
            backgroundImage:
              "linear-gradient(354deg, rgb(136 197 190) 0%, rgb(9 164 150) 100%)",
            animation: "gradient 15s ease infinite"
          }}
          onClick={handleClick}
        />
      </Flex>
    );

  return (
    <div data-testid="accountPageLink">
      <ActionButton
        dataPh="account button"
        icon={<IoPerson size={20} color={theme.color.grey[600]} />}
        background={theme.color.grey[400]}
        action={handleClick}
      />
    </div>
  );
};
