import { ActivityRoutes } from "features/activities";
import { useActivities } from "features/activities/hooks/useActivities";
import { CustomPage, CustomPages } from "../types";
import { useSite } from "./useSite";

type Data = {
  pages: string[];
  entities: Record<string, CustomPage>;
};

/** Reduce the Sites custom pages and correctly return all the activityIds needed for each page. */
export const useCustomPages = () => {
  const { data: { customExplorePages } = {}, isFetched } = useSite();
  const { data: { entities: activities } = {} } = useActivities();

  if (!customExplorePages) return;

  const customPages: CustomPages =
    isFetched && JSON.parse(JSON.parse(customExplorePages) || "[]");

  return customPages?.pages?.reduce(
    (acc: Data, page) => {
      acc.pages.push(page.link);

      const activityIDs: string[] = [];

      /** Add any activity ids related to page */
      if (page?.IDs) {
        /** Checking that each one exists in the activities incase there are some rogue draft activities */
        page.IDs.forEach(
          (id: string) => activities?.[id] && activityIDs.push(id)
        );
      }

      /** Get all activities with the pages types */
      if (page?.types?.length) {
        page.types.forEach(type => {
          const typeActivities = Object.values(activities || {})?.filter(
            activity => activity.type === type
          );

          activityIDs.push(...typeActivities.map(activity => activity.id));
        });
      }

      /** Get all activities with the pages tags */
      if (page?.tags?.length) {
        page.tags.forEach((tag: string) => {
          const tagActivities = Object.values(activities || {})?.filter(
            activity =>
              activity.tags?.items.find(({ tag: { name } }) => name === tag)
          );

          activityIDs.push(...tagActivities.map(activity => activity.id));
        });
      }

      /** Remove any duplicate IDs */
      const IDs = [...new Set(activityIDs)];

      acc.entities[page.link] = {
        ...page,
        IDs,
        route: `${ActivityRoutes.Activities}?title=${encodeURIComponent(
          page.link
        )}`
      };

      return acc;
    },
    {
      pages: [],
      entities: {}
    }
  );
};
