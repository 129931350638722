import { Box, Flex, Show, Text } from "@cloudline/cloudline-styled-components";
import { Image } from "components/Image";
import { motion } from "framer-motion";
import Link from "next/link";
import { memo } from "react";
import styled from "styled-components";
import { APP_SPACING } from "../../../components/Page";
import { useActivity } from "../hooks/useActivity";
import { useWaitTimes } from "../hooks/useWaitTimes";
import { WaitTimeVisibility } from "../types";
import { ActivityStatusTag } from "./ActivityStatusTag";

interface CardContainerProps {
  $fullWidth?: boolean;
}

export const CardContainer = styled(motion.div)<CardContainerProps>`
  height: 296px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  transform: translateZ(0);
  width: ${({ $fullWidth }: CardContainerProps) =>
    $fullWidth ? `calc(100vw - ${APP_SPACING * 2}px)` : "296px"};
`;

export const Gradient = styled.div`
  height: 100%;
  width: 100%;
  background: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0) 50%
    ),
    linear-gradient(
      162.48deg,
      rgba(0, 0, 0, 0.43) 3.19%,
      rgba(0, 0, 0, 0.046875) 37.22%,
      rgba(0, 0, 0, 0) 54.89%
    );
  position: absolute;
  top: 0;
  z-index: 5;
`;

interface Props {
  id: string;
  $fullWidth?: boolean;
}

export const ActivityCard = memo(function ActivityCard({
  id,
  $fullWidth
}: Props) {
  const activity = useActivity(id);
  const { coverImage, name, description } = activity;

  const { waitTimesEnabled, waitTime } =
    useWaitTimes({
      id,
      location: WaitTimeVisibility.Explore
    }) || {};

  return (
    <Link href={`/activities/${id}`} data-ph="activity card">
      <CardContainer whileTap={{ scale: 0.95 }} $fullWidth={$fullWidth}>
        <Image alt={name} s3Key={coverImage?.key || ""} fill priority />
        <Gradient />
        <Box
          h="100%"
          w="100%"
          position="absolute"
          bottom={0}
          z={5}
          p={4}
          display="flex"
          direction="column"
          justify="space-between"
        >
          <Flex
            align="center"
            justify={waitTimesEnabled ? "space-between" : "flex-end"}
          >
            <Show when={waitTimesEnabled}>
              <Text size={14} weight={700} color="#fff">
                {waitTime} min
              </Text>
            </Show>

            <ActivityStatusTag activity={activity} />
          </Flex>

          <Flex direction="column" gap={1}>
            <Text
              size={36}
              weight={600}
              color="grey.100"
              lineHeight="1.2"
              lines={1}
            >
              {name}
            </Text>
            <Text
              size={14}
              weight={300}
              color="grey.100"
              lines={2}
              lineHeight="17px"
            >
              {description}
            </Text>
          </Flex>
        </Box>
      </CardContainer>
    </Link>
  );
});
