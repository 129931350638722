import { useQuery } from "@tanstack/react-query";
import { useSite } from "features/site/hooks/useSite";
import { getAuthMode } from "utils/getAuthMode";
import { Data, normalise } from "utils/normalise";
import { requestWithErrHandler } from "utils/requestWithErrHandler";
import { Applet, ListAppletsQuery } from "../types";

export const fetchApplets = async (siteID: string) => {
  const authMode = await getAuthMode();

  const response = await requestWithErrHandler<
    ListAppletsQuery,
    { siteID: string }
  >(listApplets, { siteID }, authMode);

  const applets = response?.data?.listApplets?.items.filter(
    ({ _deleted, isEnabled }) => !_deleted || !!isEnabled
  );

  return normalise<Applet>(applets || []);
};

export const useApplets = () => {
  const { data: site } = useSite();

  return useQuery<Data<Applet>, unknown>(
    ["applets"],
    () => fetchApplets(site?.id || ""),
    {
      enabled: !!site?.id
    }
  );
};

const listApplets = /* GraphQL */ `
  query ListApplets($siteID: ID!) {
    listApplets(filter: { siteID: { eq: $siteID } }) {
      items {
        _deleted
        _version
        id
        name
        description
        order
        url
        isEnabled
        image {
          bucket
          region
          key
        }
      }
    }
  }
`;
