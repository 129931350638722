import { Box, theme } from "@cloudline/cloudline-styled-components";
import { Checkbox } from "@radix-ui/themes";
import { ReactElement, ReactNode, memo } from "react";
import { IoMdClose } from "react-icons/io";
import Sheet from "react-modal-sheet";
import styled from "styled-components";

const CustomSheet = styled(Sheet)`
  .react-modal-sheet-content {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: ${theme.color.grey[100]};
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;

    > div {
      display: flex;
      flex-direction: column;

      ::-webkit-scrollbar {
        display: none;
      }

      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
`;

const CloseIcon = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 100;
  color: ${theme.color.grey[900]};
  height: 32px;
  width: 32px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

interface Props {
  Content: ReactNode;
  AdditionalContent?: ReactElement;
  onTap?: () => void;
  onClose?: () => void;
  onCloseEnd?: () => void;
  fixedHeight?: number;
  isOpen: boolean;
}

export const GenericBottomSheet = memo(function GenericBottomSheet({
  Content,
  AdditionalContent,
  onTap,
  onClose,
  onCloseEnd,
  isOpen
}: Props) {
  return (
    <CustomSheet
      isOpen={isOpen}
      onClose={() => {
        onClose?.();
      }}
      onCloseEnd={onCloseEnd}
      disableDrag
      initialSnap={0}
      detent="content-height"
      mountPoint={document.getElementById("portal") || document.body}
    >
      <Sheet.Container>
        <CloseIcon onClick={onClose}>
          <IoMdClose size="20px" />
        </CloseIcon>

        <Sheet.Content>
          <Sheet.Scroller>{Content}</Sheet.Scroller>
          <Box position="sticky" bottom="0px">
            {AdditionalContent}
          </Box>
        </Sheet.Content>
      </Sheet.Container>

      <Sheet.Backdrop onTap={onTap} />
    </CustomSheet>
  );
});
