import { Box, Text, theme } from "@cloudline/cloudline-styled-components";
import { useSite } from "features/site/hooks/useSite";
import Link from "next/link";
import { RiSearchLine } from "react-icons/ri";

/** Search bar for the homepage. Goes to the Search page when clicked. */
export const HomeSearchBar = () => {
  const { data: site } = useSite();

  return (
    <Box w="100%">
      <Link href="/search" data-ph="search bar">
        <Box
          display="flex"
          align="center"
          gap={2}
          w="100%"
          h="48px"
          py={3}
          px={4}
          border={`1px solid ${theme.color.grey[400]}`}
          rounded="md"
          bg="#fff"
        >
          <RiSearchLine size={16} color={theme.color.grey[600]} />
          <Text weight={400} color={theme.color.grey[600]} lines={1}>
            Search {site?.name}
          </Text>
        </Box>
      </Link>
    </Box>
  );
};
