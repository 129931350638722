import { Box, Flex, Show, theme } from "@cloudline/cloudline-styled-components";
import Autoplay from "embla-carousel-autoplay";
import useEmblaCarousel, { EmblaCarouselType } from "embla-carousel-react";
import { memo, useCallback, useEffect, useState } from "react";
import { ViewAllCard } from "./ViewAllCarouselCard";

type DotProps = {
  onClick: () => void;
  selected: boolean;
};

const DotButton = ({ selected, onClick }: DotProps) => (
  <Box
    as="button"
    w={5}
    h={5}
    position="relative"
    p={1}
    mx={1}
    rounded="full"
    z={100}
    onClick={onClick}
    bg={selected ? theme.color.primary[500] : theme.color.grey[400]}
  />
);

export interface CarouselProps {
  id: string;
  $fullWidth?: boolean;
  clickAllowed?: () => boolean;
  currentCard?: () => number;
  index?: number;
}

export type CarouselComponent = (Props: CarouselProps) => JSX.Element | null;

interface Props {
  IDs: string[];
  useDots?: boolean;
  isAutoplay?: boolean;
  SubComponent: CarouselComponent;
  link?: string;
  sliceLength?: number;
  viewMoreText?: string;
  startIndex?: number;
  selectFunc?: (index: number) => void;
  loop?: boolean;
  goTo?: number;
}

/** Carousel for images and text. */
export const EmblaCarousel = memo(function EmblaCarousel({
  IDs,
  useDots,
  isAutoplay,
  SubComponent,
  link,
  sliceLength = 0,
  viewMoreText,
  startIndex = 0,
  selectFunc,
  loop,
  goTo
}: Props) {
  const [emblaRef, embla] = useEmblaCarousel(
    {
      align: 0,
      containScroll: "keepSnaps",
      loop,
      startIndex
    },
    isAutoplay ? [Autoplay()] : []
  );
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const scrollTo = useCallback(
    (index: number) => embla?.scrollTo(index),
    [embla]
  );

  const onInit = useCallback((emblaApi: EmblaCarouselType) => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  const onSelect = useCallback(() => {
    if (!embla) return;

    const currentIndex = embla.selectedScrollSnap();
    // Checking its not the same index to limit the amount of operations.
    if (selectedIndex !== currentIndex) {
      setSelectedIndex(currentIndex as number);
      selectFunc?.(currentIndex as number);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [embla, selectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onInit(embla);

    /**
     * The only place we have a select function is Map Carousel.
     * Settle works better for the map moving (possibly with map upgrade we wont need to wait for settle).
     * */
    embla.on(selectFunc ? "settle" : "select", onSelect);
  }, [embla, onInit, onSelect, selectFunc]);

  useEffect(() => {
    if (!embla) return;

    if (IDs.length) {
      embla.reInit({ startIndex, loop });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [embla, IDs]);

  /** If passed a specific index to move to, move to it. */
  useEffect(() => {
    scrollTo(goTo || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goTo]);

  const displayViewAllCard = IDs?.length > sliceLength;

  const viewAllText = viewMoreText ? viewMoreText : `View all (${IDs?.length})`;

  return (
    <>
      <Box position="relative" ref={emblaRef}>
        <Flex gap={4}>
          {IDs.slice(0, sliceLength ? sliceLength : IDs.length).map(
            (id, index) => (
              <Box
                position="relative"
                key={id}
                pr={index === IDs.length - 1 ? 4 : 0}
              >
                <SubComponent
                  id={id}
                  key={id}
                  $fullWidth={IDs.length === 1}
                  clickAllowed={embla?.clickAllowed}
                  currentCard={embla?.selectedScrollSnap}
                  index={index}
                />
              </Box>
            )
          )}

          <Show when={displayViewAllCard}>
            <ViewAllCard link={link} text={viewAllText} />
          </Show>
        </Flex>

        <Show when={useDots}>
          <Box w="100%" position="absolute" bottom="20px" flex justify="center">
            {scrollSnaps?.map((_, index) => (
              <DotButton
                key={index}
                selected={index === selectedIndex}
                onClick={() => scrollTo(index)}
              />
            ))}
          </Box>
        </Show>
      </Box>
    </>
  );
});
