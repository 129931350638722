import { ArrowRight } from "@phosphor-icons/react";
import { Flex, IconButton, Text } from "@radix-ui/themes";
import Link from "next/link";

type ViewAllCardProps = {
  link?: string;
  text?: string;
};

export const ViewAllCard = ({ link, text }: ViewAllCardProps) => {
  return (
    <Link href={link ? link : "/"}>
      <Flex
        direction="column"
        height="100%"
        justify="center"
        align="center"
        gap="4"
        className="w-[96px]"
      >
        <IconButton variant="soft">
          <ArrowRight size="24px" />
        </IconButton>
        <Text size="2" weight="medium" color="gray">
          {text}
        </Text>
      </Flex>
    </Link>
  );
};
