import { Box, Text } from "@cloudline/cloudline-styled-components";
import { Image } from "components/Image";
import Link from "next/link";
import { useRouter } from "next/router";
import { memo } from "react";
import styled from "styled-components";
import { APP_SPACING } from "../../../components/Page";
import { ActivityType } from "../../activities";
import { useActivity } from "../../activities/hooks/useActivity";
import { useNotices } from "../hooks/useNotices";
import { Notice, NoticeType } from "../types";

const NoticeSlide = styled.button`
  height: 272px;
  width: calc(100vw - ${APP_SPACING * 2}px);
  position: relative;
  overflow: hidden;
  padding: 0px;
  border-radius: 1rem;
  transform: translateZ(0);

  @media (min-width: 360px) {
    height: 360px !important;
  }
`;

const Gradient = styled.div`
  height: 100%;
  width: 100%;
  background: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0) 50%
    ),
    linear-gradient(
      162.48deg,
      rgba(0, 0, 0, 0.43) 3.19%,
      rgba(0, 0, 0, 0.046875) 37.22%,
      rgba(0, 0, 0, 0) 54.89%
    );
  position: absolute;
  top: 0;
`;

interface Props {
  // Has to be named id for the Embla carousel props.
  id: string;
}

const Content = ({ notice }: { notice: Notice }) => (
  <NoticeSlide data-ph="notice card">
    <Box h="360px" w={`calc(100vw - ${APP_SPACING * 2}px)`}>
      <Image
        alt={notice.title}
        s3Key={notice.coverImage.key}
        fill
        style={{ objectFit: "cover" }}
        priority
      />
    </Box>

    <Gradient />
    <Box
      w="100%"
      h="100%"
      flex
      justify="flex-end"
      gap={1}
      column
      position="absolute"
      top="0px"
      p="20px 20px 56px 20px"
    >
      <Text color="#fff" size={36} weight={700} lineHeight="1" align="left">
        {notice?.title}
      </Text>
      <Text color="grey.100" size={16} weight={300} align="left">
        {notice?.description}
      </Text>
    </Box>
  </NoticeSlide>
);

/** Information card for notices. Displays the title, image and description. */
export const NoticeCard = memo(function NoticeCard({ id: noticeID }: Props) {
  const { push } = useRouter();
  const { data: { entities } = {} } = useNotices();

  const notice = entities?.[noticeID];
  const activity = useActivity(notice?.activityID);

  function handlePoiActivity() {
    push(`/map?mapId=${notice?.activityID}`);
  }

  if (!notice) return null;

  if (notice.type === NoticeType.Activity) {
    /** When the activity type is POI then redirect to map as there is no activity page for POIs */
    if (activity?.type === ActivityType.Poi) {
      return (
        <Box onClick={handlePoiActivity}>
          <Content notice={notice} />
        </Box>
      );
    }

    return (
      <Link href={`/activities/${notice?.activityID}`}>
        <Content notice={notice} />
      </Link>
    );
  }

  if (notice.type === NoticeType.Link) {
    return (
      <a href={notice.link || ""} rel="noreferrer" target="_blank">
        <Content notice={notice} />
      </a>
    );
  }

  if (notice.type === NoticeType.Embed) {
    return (
      <Link href={`/embed?noticeId=${notice?.id}`}>
        <Content notice={notice} />
      </Link>
    );
  }

  return <Content notice={notice} />;
});
